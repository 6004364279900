<template>
  <li>
    <div class="flex flex-row items-center">
      <DynamicPictogram
        v-if="entry['icon-desktop'] || entry.icon"
        :class="`pr-2 hidden lg:inline ${entry.classes || ''}`"
        :name="`${entry['icon-desktop'] || entry.icon}`"
        size="md"
      />
      <DynamicPictogram
        v-if="entry['icon-mobile'] || entry.icon"
        :class="`pr-2 inline lg:hidden ${entry.classes || ''}`"
        :name="`${entry['icon-mobile'] || entry.icon}`"
        size="md"
      />
      <NuxtLink
        v-if="href.length > 0"
        :href="href"
        :external="!!entry.newWindow"
        :target="entry.newWindow ? '_blank' : null"
        class="mt-4 inline-block text-black/85 lg:mt-0"
        >{{ entry.name }}</NuxtLink
      >
      <p v-else class="mt-14 font-semibold text-black/85 lg:mt-0">
        {{ entry.name }}
      </p>
    </div>

    <div v-for="subentry in entry.children" :key="subentry.entityId">
      <ul class="lg:mt-4">
        <FooterMenuEntry :entry="subentry" />
      </ul>
    </div>
  </li>
</template>

<script lang="ts" setup>
const { entry } = defineProps({
  entry: {
    type: Object,
    required: true,
  },
});

const href = computed(() => entry?.path || '');
</script>
