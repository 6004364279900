<template>
  <div>
    <div class="flex flex-col lg:flex-row">
      <div class="order-2 flex basis-full flex-col lg:order-1 lg:basis-2/3">
        <p
          v-for="paragraph in $tm('footer.message_paragraphs')"
          :key="paragraph"
          class="mt-4 first-of-type:mt-0"
        >
          {{ $rt(paragraph) }}
        </p>
      </div>
    </div>
  </div>
</template>
